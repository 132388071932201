import { React, useState, useEffect } from "react";

import "simplebar-react/dist/simplebar.min.css";

import {
  Container,
  Row,
  Col,
  Table,
  ListGroup,
  Button,
  Navbar,
} from "react-bootstrap";

import "./Stats.css";
import SimpleBar from "simplebar-react";
import api from "./DAL/api";
import { useNavigate } from "react-router-dom";
import botIcon from "./DAL/assets/bot.png";

function Stats({ data, sendData }) {
  const navigate = useNavigate();
  const [highlight, setHighlight] = useState(0);

  const refreshData = async () => {
    try {
      let res = await api.getChats({ creds: data.user });
      sendData({ user: data.user, info: res });
    } catch (e) {
      console.log(e);
      navigate("/");
    }
  };

  const delData = async () => {
    try {
      let res = await api.delChats({ creds: data.user });
      sendData({ user: data.user, info: res });
    } catch (e) {}
  };

  useEffect(() => {
    !data.user && navigate("/");
  }, [data, navigate]);

  return data.info.length >= 1 ? (
    <Container className="login-container">
      <Navbar className="top-nav" fixed="top" align-items-center="true">
        <Container fluid>
          <Navbar.Brand>
            <img
              alt=""
              src={botIcon}
              width="60"
              height="60"
              className="d-inline-block"
            />{" "}
            <span className="nav-txt">Shiekh-GPT</span>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Row>
        <Col align="center">
          <SimpleBar
            style={{ maxHeight: 300 }}
            forceVisible="n"
            autoHide={true}
          >
            <Table
              striped
              bordered
              hover
              variant="dark"
              size="sm"
              responsive="true"
              className="table-container"
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Chat</th>
                </tr>
              </thead>
              <tbody>
                {data.info.map((item, index) => (
                  <tr
                    onClick={() => {
                      setHighlight(index);
                    }}
                    key={index}
                  >
                    <td className="date-td">{item.date}</td>
                    <td className="time-td">{item.time} </td>
                    <td className="ua-td">{item.userChat}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </SimpleBar>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup className="user-list-unique">
            <ListGroup.Item className="list-item-users">
              <i className="fas fa-network-wired details-icons"></i>IP:{" "}
              {data.info[highlight].userIP}
            </ListGroup.Item>
            <ListGroup.Item className="list-item-users">
              <i className="fa-solid fa-mobile-screen-button details-icons"></i>
              UA: {data.info[highlight].userUA}
            </ListGroup.Item>
            <ListGroup.Item className="list-item-users">
              <i className="fas fa-map-marker-alt details-icons"></i>GEO:{" "}
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${
                  data.info[highlight].userGenGeo
                    ? data.info[highlight].userGenGeo.ll[0]
                    : "not found"
                },${
                  data.info[highlight].userGenGeo
                    ? data.info[highlight].userGenGeo.ll[1]
                    : "not found"
                }`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {data.info[highlight].userGenGeo
                  ? `Estimated: ${data.info[highlight].userGenGeo.ll[0]},${data.info[highlight].userGenGeo.ll[1]}`
                  : "not found"}
              </a>
            </ListGroup.Item>
            <ListGroup.Item className="list-item-users">
              <i className="fa-solid fa-map details-icons"></i>LOCATION:{" "}
              {data.info[highlight].userGenGeo
                ? data.info[highlight].userGenGeo["country"]
                : "not found"}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row className="button-row">
        <Col align="right">
          <Button className="clear-btn" onClick={delData}>
            CLEAR <i className="fas fa-trash-alt"></i>
          </Button>
        </Col>
        <Col align="left">
          <Button onClick={refreshData} className="refresh-btn">
            REFRESH <i className="fas fa-sync-alt"></i>
          </Button>
        </Col>
      </Row>
    </Container>
  ) : (
    <>
      <Navbar className="top-nav" fixed="top" align-items-center="true">
        <Container fluid>
          <Navbar.Brand>
            <img
              alt=""
              src={botIcon}
              width="60"
              height="60"
              className="d-inline-block"
            />{" "}
            <span className="nav-txt">Shiekh-GPT</span>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container className="login-container">
        <Row className="loading-row">
          <Col>
            <i className="em em-thinking_face"></i>
          </Col>
        </Row>

        <Row className="button-row">
          <Col align="center">
            <Button onClick={refreshData} className="refresh-btn">
              REFRESH <i className="fas fa-sync-alt"></i>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Stats;
