import axios from "axios";

const getChat = async (userQuery) => {
  try {
    let res = await axios.get("https://shiekh.herokuapp.com/api/chat", {
      params: { prompt: userQuery.toString() },
    });
    if (res) {
      let chatReply = res.data;
      if (chatReply.message === "Request failed with status code 429")
        return "نواجه بعض الصعوبات في الوقت الحالي، يرجى المحاولة مرة أخرى لاحقاً";
      return chatReply;
    }
  } catch (e) {
    return "نواجه بعض الصعوبات في الوقت الحالي، يرجى المحاولة مرة أخرى لاحقاً";
  }
};

const postChat = async (data) => {
  let res = await axios.post(
    "https://shiekh.herokuapp.com/api/chat/newchat",
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return res.data;
};

const getChats = async (user) => {
  let res = await axios.get("https://shiekh.herokuapp.com/api/chat/allchats", {
    params: { username: user.creds.username, password: user.creds.password },
  });
  let sortedData = res.data.sort(function (a, b) {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  let formatData = sortedData.map((element) => {
    return {
      ...element,
      date: new Date(element.createdAt).toISOString().substring(0, 10),
      time: element.createdAt.match(/\d\d:\d\d/)[0],
    };
  });

  return formatData;
};

const delChats = async (data) => {
  let res = await axios.delete(
    "https://shiekh.herokuapp.com/api/chat/allchats",
    {
      data: data.creds,
    }
  );
  return res.data;
};

const api = {
  getChat,
  postChat,
  getChats,
  delChats,
};

export default api;
