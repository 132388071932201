import { useState, React } from "react";
import "./App.css";
import Chat from "./Chat.js";

import Manager from "./Manager.js";
import Stats from "./Stats.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [data, setData] = useState({ user: false, info: false });

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Chat />}></Route>
          <Route
            exact
            path="/manager"
            element={<Manager sendData={setData} />}
          ></Route>
          <Route
            exact
            path="/stats"
            element={<Stats data={data} sendData={setData} />}
          ></Route>
          <Route exact path="/:something" element={<Chat />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
