import { React, useEffect, useState, useRef } from "react";

import {
  Row,
  Col,
  ListGroup,
  Spinner,
  Container,
  Form,
  Navbar,
  Alert,
} from "react-bootstrap";
import "./Chat.css";
import "./TopNav.css";
import userIcon from "./DAL/assets/user.png";
import botIcon from "./DAL/assets/bot.png";
import toast, { Toaster } from "react-hot-toast";
import { TypeAnimation } from "react-type-animation";

import api from "./DAL/api.js";

function Chat() {
  const [conversation, setConversation] = useState([]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const bottomRef = useRef(null);
  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversation]);

  const promptSample = [
    { prompt: "من هم الثلاثة من الصحابية الذين تشتاق إليهم الجنة؟" },
    { prompt: "شيء هام يفعله الإنسان حين يقوم من نومه؟" },
    { prompt: "ما هو الذكاء الاصطناعي؟" },
  ];

  const warnings = [
    { warning: "قد ينتج بعض المعلومات الخاطئة أحيانًا " },
    { warning: "قد ينتج أحيانًا تعليمات ضارة أو محتوى متحيز" },
    { warning: "المعلومات المحدودة بشأن العالم والأحداث بعد عام 2021" },
  ];

  const abilities = [
    { ability: "بحث كامل عن العقيدة الاسلامية" },
    { ability: "تفسير الأحلام" },
    { ability: "رفض الطلبات غير الملائمة" },
  ];

  const handleClick = (txt) => {
    setText(txt);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const resetChat = () => {
    setSpinning(true);
    setTimeout(() => setSpinning(false), 1000);
    if (!loading) {
      let newConversation = [];
      setConversation([...newConversation]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (text) {
      setLoading(true);
      let newConversation = conversation;
      newConversation.push({ role: "user", content: text });
      setConversation([...newConversation]);
      setText("");
      let botRes = await api.getChat(text);
      newConversation.push({
        role: "bot",
        content: botRes,
      });
      let data = {
        newUser: {
          userCreds: true,
          userChat: text,
        },
      };
      await api.postChat(data);
      setConversation([...newConversation]);
      setLoading(false);
    }
  };

  const clipboardCopy = (txtCopy) => {
    toast.success("نسخ");
    navigator.clipboard.writeText(txtCopy);
  };

  return (
    <div className="top-div">
      <Navbar className="top-nav" fixed="top" align-items-center="true">
        <Container fluid>
          <Navbar.Brand>
            <img
              alt=""
              src={botIcon}
              width="60"
              height="60"
              className="d-inline-block"
            />{" "}
            <span className="nav-txt">SHEIKH-GPT</span>
          </Navbar.Brand>
          <i
            className={
              spinning && !loading
                ? "fa-solid fa-rotate refresh-icon"
                : "fa-solid fa-rotate refresh-icon spin-once"
            }
            onClick={resetChat}
          ></i>
        </Container>
      </Navbar>
      {conversation.length === 0 ? (
        <Container fluid className="hero-container">
          <Row className="hero-row">
            <Col align="center">
              <span className="hero-title">
                <i className="fa-regular fa-comment-dots fa-flip-horizontal"></i>{" "}
                شيخ جي-بي-تي{" "}
              </span>
            </Col>
          </Row>
          <Row className="subtitle-row">
            <Col>
              <i className="fa-regular fa-sun sample-icons"></i>
              <span className="sub-title">أمثلة</span>
            </Col>
          </Row>
          {promptSample.map((item, i) => (
            <Row className="warning-row" key={i}>
              <Col align="center">
                <Alert
                  className="alert-item"
                  key={i}
                  variant="dark"
                  onClick={() => {
                    handleClick(item.prompt);
                  }}
                >
                  <i className="fa-solid fa-arrow-left-long list-arrow"></i>
                  {item.prompt}
                </Alert>
              </Col>
            </Row>
          ))}
          <Row className="subtitle-row">
            <Col>
              <i className="fa-solid fa-bolt sample-icons"></i>
              <span className="sub-title">القدرات</span>
            </Col>
          </Row>
          {abilities.map((item, i) => (
            <Row className="warning-row" key={i}>
              <Col align="center">
                <Alert className="alert-item" key={i} variant="dark">
                  {item.ability}
                </Alert>
              </Col>
            </Row>
          ))}
          <Row className="subtitle-row">
            <Col>
              <i className="fa-solid fa-triangle-exclamation sample-icons"></i>
              <span className="sub-title">القيود</span>
            </Col>
          </Row>
          {warnings.map((item, i) => (
            <Row className="warning-row" key={i}>
              <Col align="center">
                <Alert className="alert-item" key={i} variant="dark">
                  <span>{item.warning}</span>
                </Alert>
              </Col>
            </Row>
          ))}

          <Row className="conversation-row" dir="rtl">
            <Col>
              <Navbar bg="light" fixed="bottom" className="nav-bottom p-0">
                <Row className="form-row w-100">
                  <Col xs={11}>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group
                        className="mb-3 form-box"
                        controlId="formBasicEmail"
                      >
                        <Form.Control
                          placeholder="أرسل رسالة..."
                          className="form-box"
                          plain="true"
                          value={text}
                          onChange={handleChange}
                          disabled={loading ? true : false}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col className="icon-col" xs={1}>
                    <span>
                      {loading ? (
                        <Spinner animation="border" role="status"></Spinner>
                      ) : (
                        <i
                          className="fa-solid fa-play fa-flip-horizontal submit-icon"
                          onClick={handleSubmit}
                        ></i>
                      )}
                    </span>
                  </Col>
                </Row>
              </Navbar>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className="chat-container ">
          <Row className="chat-box">
            <Col className="chat-col">
              <ListGroup variant="flush">
                {conversation.map((item, i) => (
                  <ListGroup.Item
                    className={
                      item.role === "user"
                        ? "user-list chat-effect"
                        : "bot-list chat-effect alert-item"
                    }
                    key={i}
                    onClick={() => {
                      item.role === "user"
                        ? handleClick(item.content)
                        : clipboardCopy(item.content);
                    }}
                  >
                    <Row dir="rtl">
                      <Col xs={2}>
                        <img
                          alt=""
                          src={item.role === "user" ? userIcon : botIcon}
                          className="user-icon"
                        />
                      </Col>
                      <Col xs={10} align="right" className="item-text-col">
                        <span className="text-section">
                          {item.role === "user" && item.content}
                          {item.role === "bot" && (
                            <TypeAnimation
                              sequence={[
                                // Same String at the start will only be typed once, initially
                                item.content,
                                1000,
                              ]}
                              speed={50}
                              repeat={Infinity}
                            />
                          )}
                        </span>
                        <i
                          className={
                            item.role === "user"
                              ? "fa-solid fa-arrow-left-long list-arrow share-icon"
                              : "fa-solid fa-share fa-flip-horizontal share-icon"
                          }
                        ></i>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>

          <Row className="conversation-row" dir="rtl">
            <Col>
              <Navbar bg="light" fixed="bottom" className="nav-bottom p-0">
                <Row className="form-row w-100">
                  <Col xs={11}>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group
                        className="mb-3 form-box"
                        controlId="formBasicEmail"
                      >
                        <Form.Control
                          placeholder="أرسل رسالة..."
                          className="form-box"
                          plain="true"
                          value={text}
                          onChange={handleChange}
                          disabled={loading ? true : false}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col className="icon-col" xs={1}>
                    <span>
                      {loading ? (
                        <Spinner animation="border" role="status"></Spinner>
                      ) : (
                        <i
                          className="fa-solid fa-play fa-flip-horizontal submit-icon"
                          onClick={handleSubmit}
                        ></i>
                      )}
                    </span>
                  </Col>
                </Row>
                <Toaster
                  containerStyle={{
                    position: "bottom-center	",
                  }}
                />
              </Navbar>
            </Col>
          </Row>
          <div ref={bottomRef} />
        </Container>
      )}
    </div>
  );
}

export default Chat;
