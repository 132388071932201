import { React, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Navbar,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Manager.css";
import api from "./DAL/api.js";
import botIcon from "./DAL/assets/bot.png";

function Manager({ sendData }) {
  const navigate = useNavigate();

  const [validInput, setValidInput] = useState(false);

  const [userInput, setInput] = useState({
    username: "",
    password: "",
  });

  const changeInput = (field, val) => {
    let newInput = userInput;
    if (field === "username") {
      setInput({ ...newInput, username: val });
    } else if (field === "password") {
      setInput({ ...newInput, password: val });
    }
  };

  useEffect(() => {
    if (userInput.username.length > 1 && userInput.password.length > 1) {
      setValidInput(true);
    } else if (
      userInput.username.length <= 1 ||
      userInput.password.length <= 1
    ) {
      setValidInput(false);
    }
  }, [userInput, validInput]);

  const handleLogin = async () => {
    try {
      let res = await api.getChats({ creds: userInput });
      sendData({ user: userInput, info: res });
      navigate("/stats");
    } catch (e) {
      navigate("/");
    }
  };

  return (
    <>
      <Navbar className="top-nav" fixed="top" align-items-center="true">
        <Container fluid>
          <Navbar.Brand>
            <img
              alt=""
              src={botIcon}
              width="60"
              height="60"
              className="d-inline-block"
            />{" "}
            <span className="nav-txt">Shiekh-GPT</span>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid className="login-container">
        <Row className="signin-input-row">
          <Col align="center">
            <InputGroup className="mb-3 user-name-form ">
              <InputGroup.Text id="basic-addon1" className="input-icon  ">
                <i className="fas fa-user-cog"></i>
              </InputGroup.Text>
              <FormControl
                placeholder="المستخدم"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className="submit-input "
                dir="rtl"
                onChange={(e) => {
                  changeInput("username", e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup className="mb-3 user-name-form ">
              <InputGroup.Text id="basic-addon1" className="input-icon  ">
                <i className="fas fa-key"></i>
              </InputGroup.Text>
              <FormControl
                placeholder="كلمة السر"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className="submit-input "
                dir="rtl"
                onChange={(e) => {
                  changeInput("password", e.target.value);
                }}
                type="password"
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="signin-input-row">
          <Col>
            <Button
              className="submit-btn"
              disabled={validInput ? false : true}
              onClick={handleLogin}
            >
              CONNECT <i className="fas fa-unlock-alt"></i>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Manager;
